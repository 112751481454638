@tailwind base;
@tailwind components;
@tailwind utilities;

.navbarBody {
    @apply flex items-center justify-between md:space-x-32 shadow z-auto font-bold;
    background-color: rgba(10, 10, 10, 0.9);
    
    padding: 36px;
    position: sticky;
    top: 0;
    z-index: 1020;
    transition: padding 0.2s ease;

}
.navbarBody.scrolled {
    @apply px-12 py-5;
    background-color: black;
}

.homeButton {
    @apply text-5xl font-bold flex flex-row;
    font-family: 'Shape-Bold';
    transition: font-size 0.2s ease;
}
.homeButton.scrolled {
    @apply text-4xl;
}
.homeButton:hover {
    @apply cursor-pointer;
}
.homeButtonText {
    @apply text-white ml-4;
    text-shadow: #a4f06b -3px -3px 0px, #c330fb -6px -6px 0px;
}

.homeButtonLogo {
    @apply h-8 w-60 my-4;
    transition: height 0.2s ease;
}
.homeButtonLogo.scrolled {
    @apply h-7 w-auto mb-4;
}
.homeButtonLogo:hover {
    @apply cursor-pointer;
}

.navBarItems {
    @apply flex flex-row justify-between items-center w-fit md:space-x-10 max-w-screen-lg;
}
.navBarItem {
    @apply text-2xl text-white font-bold flex-nowrap md:text-xl;
    transition: font-size 0.2s ease;
}
.navBarItem.scrolled {
    @apply text-xl text-white font-bold;
}
.navBarItem:hover {
    @apply cursor-pointer;
    color: #c330fb;
}

.btnDownload {
    @apply py-3 px-6 text-white text-2xl font-extrabold rounded-full md:text-xl;
    background-color: #c330fb;
    transition: background-color 0.1s ease, transform 0.1s ease, font-size 0.2s ease, height 0.2s ease, width 0.2s ease;
}
.btnDownload.scrolled {
    @apply text-xl px-4 py-1.5;
}
.btnDownload:hover {
    @apply cursor-pointer;
    background-color: #a4f06b;
    color: black;
}