@tailwind base;
@tailwind components;
@tailwind utilities;

.navbarBody {
    @apply flex items-center justify-between shadow z-auto font-bold;
    background-color: rgba(0, 0, 0, 0.9);
    
    padding: 36px;

}
.navbarBody.scrolled {
    @apply px-12 py-5;
    background-color: black;
}

.homeButtonLogo {
    @apply h-6 my-4;
    transition: height 0.2s ease;
}
.homeButtonLogo.scrolled {
    @apply h-7 mb-4;
}
.homeButtonLogo:hover {
    @apply cursor-pointer;
}