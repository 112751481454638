@tailwind base;
@tailwind components;
@tailwind utilities;

.homePageBody {
    @apply h-fit w-fit;
    background-color: black;
    background-size: cover;
    background-image: linear-gradient(to bottom, black, rgba(30, 30, 30, 0.9), rgba(30, 30, 30, 0.9), rgba(40, 40, 40, 0.9), rgba(50, 50, 50, 0.9)),
    url('../../../assets/images/home-page-cover.jpg');
}
.homeBody {
    @apply w-screen;
    height: 2000px;
}

.homeSection1 {
    @apply flex flex-row justify-between w-full mt-40;
}
.bigHeading {
    @apply text-8xl text-white font-extrabold flex flex-col p-10;
}
.greenText {
    color: #a4f06b;
}
.pinkText {
    color: #c330fb;
}
.subHeading {
    @apply text-3xl mt-14;
}

.homeSection2 {
    @apply flex flex-col justify-between w-full mt-60;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), black);
}
.featuresHeading {
    @apply text-6xl text-white font-extrabold p-10 mt-20;
}
.featuresSubHeading {
    @apply text-2xl text-white font-bold px-10
}
.featuresBody {
    @apply grid grid-cols-4 grid-rows-5 justify-center px-16 mt-20 mb-72;
}
.featureIconContainer {
    @apply col-span-1 row-span-2 w-full flex items-center justify-center text-center;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureIconContainerEnd {
    @apply col-span-1 row-span-2 w-full flex items-center justify-center text-center;
}
.feature1 {
    @apply flex items-center justify-center;
    border-radius: 100%;
    height: 15vh;
    width: 15vh;
    background-color: #c330fb;
}
.feature2 {
    @apply flex items-center justify-center;
    border-radius: 100%;
    height: 15vh;
    width: 15vh;
    background-color: #a4f06b;
}
.feature {
    @apply flex flex-col col-span-1 items-center w-full px-8 py-16;
}
.featureDividerLine {
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
}
.featureHead {
    @apply text-2xl font-extrabold text-white text-center px-8 pt-12 col-span-1 row-span-1;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureHeadEnd {
    @apply text-2xl font-extrabold text-white text-center px-8 pt-12 col-span-1 row-span-1;
}
.featureBody {
    @apply text-xl font-normal text-white text-center p-8 col-span-1 row-span-2;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureBodyEnd {
    @apply text-xl font-normal text-white text-center p-8 col-span-1 row-span-2;
}
.featureIcon {
    @apply h-16 w-16;
}
.featureIconCorrected {
    @apply h-16 w-16;
    margin-top: -14px;
}

.homeSection3 {
    @apply flex flex-col justify-between w-full pb-40;
    background-image: linear-gradient(to bottom, black, rgba(30, 30, 30, 0.9), rgba(40, 40, 40, 0.9), rgba(30, 30, 30, 0.9), black),
    url('../../../assets/images/creator-background.png');
    background-size: cover;
}
.communityHeading {
    @apply text-6xl text-white font-extrabold px-10 pt-10;
}
.communitySubHeading {
    @apply text-2xl text-white font-extrabold p-10;
}
.communityBody {
    @apply grid grid-cols-5 items-center p-10 my-5;
}
.screenImage {
    @apply col-span-2;
    height: 600px;
}
.creatorSteps {
    @apply flex flex-col justify-start items-start col-span-3;
}
.creatorStepContainer {
    @apply flex flex-row justify-evenly items-center text-2xl p-4;
}
.creatorStepNumberGreen {
    @apply font-extrabold h-10 w-10 flex items-center justify-center mx-5;
    border-radius: 100%;
    border-color: #a4f06b;
    border-width: 2px;
    color: #a4f06b;
}
.creatorStepNumberPink {
    @apply font-extrabold h-10 w-10 flex items-center justify-center mx-5;
    border-radius: 100%;
    border-color: #c330fb;
    border-width: 2px;
    color: #c330fb;
}
.creatorStepNumberWhite {
    @apply font-extrabold h-10 w-10 flex items-center justify-center mx-5;
    border-radius: 100%;
    border-color: #ffffff;
    border-width: 2px;
    color: #ffffff;
}
.creatorStepContent {
    @apply font-bold text-white;
}

.homeSection4 {
    @apply flex flex-col justify-between w-full pb-40;
    background-color: black;
}
.aboutUsHeading {
    @apply text-6xl text-white font-extrabold px-10 pt-20;
}
.aboutUsSubHeading {
    @apply text-2xl text-white font-extrabold pt-10 px-10;
}
.teamMembersContainer {
    @apply flex flex-row justify-center items-center px-20 pt-20;
}
.teamMember {
    @apply flex flex-col justify-center items-center w-60 mx-28;
}
.teamMemberPic {
    @apply w-60;
    height: auto;
    border-radius: 100%;
}
.teamMemberName {
    @apply font-extrabold text-2xl pt-10;
    color: #a4f06b;
}
.teamMemberTitle {
    @apply font-bold text-xl pt-4 text-white;
}

.footer {
    @apply h-28 flex flex-row justify-between items-center pe-10 w-full;
    border-top: 1px rgba(255, 255, 255, 0.2) solid;
    background: black;
}
.footerSocialMediaHandles {
    @apply flex flex-row justify-end;
}
.footerContactUs {
    @apply text-white px-10 text-lg font-bold;
}
.footerContactUsEmail {
    color: #c330fb;
}
.footerContactUsEmail:hover {
    @apply underline;
}
.socialMediaIcon {
    @apply h-12 w-12 m-4;
}
.socialMediaIcon:hover {
    @apply cursor-pointer;
}