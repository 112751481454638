@tailwind base;
@tailwind components;
@tailwind utilities;

.homePageBody {
    @apply h-fit w-fit;
    background-color: black;
}
.homeBody {
    @apply w-screen;
}

.homeSection1 {
    @apply flex flex-col justify-between w-full mt-28;
    height:600px;
    background-size: cover;
    background-image: linear-gradient(to bottom, black, rgba(40, 40, 40, 0.9), rgba(30, 30, 30, 0.9), black, black),
    url('../../../assets/images/home-page-cover.jpg');
    z-index: 0;
}
.bigHeading {
    @apply text-5xl text-white font-extrabold flex flex-col p-10 mt-16;
}
.greenText {
    color: #a4f06b;
}
.pinkText {
    color: #c330fb;
}
.subHeading {
    @apply text-2xl mt-10;
}
.btnDownload {
    @apply mt-20 -ml-2 py-3 px-6 text-white text-xl text-center font-extrabold rounded-full w-fit;
    background-color: #c330fb;
    transition: background-color 0.1s ease, transform 0.1s ease, font-size 0.2s ease;
}
.btnDownload.scrolled {
    @apply text-xl px-4 py-1.5;
}
.btnDownload:hover {
    @apply cursor-pointer;
}

.homeSection2 {
    @apply flex flex-col justify-between w-full mt-60;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), black);
    z-index: 1000;
}
.featuresHeading {
    @apply text-4xl text-white font-extrabold p-10 mt-20 text-center;
}
.featuresSubHeading {
    @apply text-2xl text-white font-bold px-10
}
.featuresBody {
    @apply grid grid-cols-2 grid-rows-8 justify-center mt-20 mb-72;
}
.featureIconContainerTL {
    @apply col-span-1 row-span-1 w-full flex items-center justify-center text-center pt-8;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureIconContainerTR {
    @apply col-span-1 row-span-1 w-full flex items-center justify-center text-center pt-8;
}
.featureIconContainerBL {
    @apply col-span-1 row-span-1 w-full flex items-center justify-center text-center pt-8;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureIconContainerBR {
    @apply col-span-1 row-span-1 w-full flex items-center justify-center text-center pt-8;
}
.feature1 {
    @apply flex items-center justify-center;
    border-radius: 100%;
    height: 10vh;
    width: 10vh;
    background-color: #c330fb;
}
.feature2 {
    @apply flex items-center justify-center;
    border-radius: 100%;
    height: 10vh;
    width: 10vh;
    background-color: #a4f06b;
}
.feature {
    @apply flex flex-col col-span-1 items-center w-full px-8 py-16;
}
.featureDividerLine {
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
}
.featureHeadTL {
    @apply text-xl font-extrabold text-white text-center px-4 col-span-1 row-span-1 pt-8;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureHeadTR {
    @apply text-xl font-extrabold text-white text-center px-4 col-span-1 row-span-1 pt-8;
}
.featureHeadBL {
    @apply text-xl font-extrabold text-white text-center px-4 col-span-1 row-span-1 pt-8;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureHeadBR {
    @apply text-xl font-extrabold text-white text-center px-4 col-span-1 row-span-1 pt-8;
}

.featureBodyTL {
    @apply text-base font-normal text-white text-center px-4 col-span-1 row-span-2;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
    border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureBodyTR {
    @apply text-base font-normal text-white text-center px-4 col-span-1 row-span-2;
    border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureBodyBL {
    @apply text-base font-normal text-white text-center px-4 col-span-1 row-span-2;
    border-right: rgba(255, 255, 255, 0.2) 1px solid;
}
.featureBodyBR {
    @apply text-base font-normal text-white text-center px-4 col-span-1 row-span-2;
}

.featureIcon {
    @apply h-9 w-9;
}
.featureIconCorrected {
    @apply h-16 w-16;
    margin-top: -14px;
}

.homeSection3 {
    @apply flex flex-col justify-between w-full pb-40;
    background-image: linear-gradient(to bottom, black, rgba(30, 30, 30, 0.9), rgba(40, 40, 40, 0.9), rgba(30, 30, 30, 0.9), black),
    url('../../../assets/images/creator-background.png');
    background-size: cover;
}
.communityHeading {
    @apply text-4xl text-center text-white font-extrabold px-10;
}
.communitySubHeading {
    @apply text-xl text-white font-extrabold p-10 text-center;
}
.communityBody {
    @apply items-center p-4 my-5;
}
.screenImage {
    @apply col-span-2;
    height: 600px;
}
.creatorSteps {
    @apply flex flex-col justify-start items-start;
}
.creatorStepContainer {
    @apply flex flex-row justify-evenly items-center text-xl p-4;
}
.creatorStepNumberGreen {
    @apply font-extrabold h-10 w-10 flex items-center justify-center mx-2;
    border-radius: 100%;
    border-color: #a4f06b;
    border-width: 2px;
    color: #a4f06b;
}
.creatorStepNumberPink {
    @apply font-extrabold h-10 w-10 flex items-center justify-center mx-5;
    border-radius: 100%;
    border-color: #c330fb;
    border-width: 2px;
    color: #c330fb;
}
.creatorStepNumberWhite {
    @apply font-extrabold h-10 w-10 flex items-center justify-center mr-5;
    border-radius: 100%;
    border-color: #ffffff;
    border-width: 2px;
    color: #ffffff;
}
.creatorStepContent {
    @apply font-bold text-white;
}

.homeSection4 {
    @apply flex flex-col justify-between w-full pb-10;
    background-color: black;
}
.aboutUsHeading {
    @apply text-4xl text-white font-extrabold px-10 pt-20 text-center;
}
.aboutUsSubHeading {
    @apply text-xl text-white font-extrabold pt-10 px-10 text-center;
}
.teamMembersContainer {
    @apply flex flex-col justify-center items-center px-20 pt-20;
}
.teamMember {
    @apply flex flex-col justify-center items-center w-60 mx-28;
}
.teamMemberPic {
    @apply h-40 w-40;
    border-radius: 100%;
}
.teamMemberName {
    @apply font-extrabold text-2xl pt-10;
    color: #a4f06b;
}
.teamMemberTitle {
    @apply font-bold text-xl pt-4 text-white pb-20;
}

.footer {
    @apply h-fit flex flex-col justify-start items-start py-8 w-full;
    border-top: 1px rgba(255, 255, 255, 0.2) solid;
    background: black;
}
.footerSocialMediaHandles {
    @apply flex flex-row justify-end px-2 mt-2 w-full;
}
.footerContactUs {
    @apply text-white px-6 text-base font-bold w-full text-end;
}
.footerContactUsEmail {
    color: #c330fb;
}
.footerContactUsEmail:hover {
    @apply underline;
}
.socialMediaIcon {
    @apply h-9 w-9 m-4;
}
.socialMediaIcon:hover {
    @apply cursor-pointer;
}